@tailwind base;
@tailwind components;
@tailwind utilities;

/* Adds the fonts */
@layer base{
  @font-face{
    font-family: "noirMedium";
    font-weight: 500;
    src: url('../public/fonts/Noir_medium.otf') format('opentype');
  }
  @font-face{
    font-family: "noirRegular";
    font-weight: 400;
    src: url('../public/fonts/Noir_regular.otf') format('opentype');
  }
  @font-face{
    font-family: "less";
    font-weight: 500;
    src: url('../public/fonts/Less.otf') format('opentype');
  }
  @font-face{
    font-family: "lessLight";
    font-weight: 300;
    src: url('../public/fonts/Less-Light.otf') format('opentype');
  }
  @font-face{
    font-family: "lessBold";
    font-weight: 700;
    src: url('../public/fonts/Less-Bold.otf') format('opentype');
  }
}

/* Menu styling */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .nav-area {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 20px;
  }
  
  .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
    border-radius: 0.125rem;
    
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    border-radius: 0.125rem;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    border-radius: 0.125rem;
    
  }
  
  button span {
    margin-left: 3px;
    
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
    color: #3B7097;
    
    
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #3B7097;
    color: #FFFFFF;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px #3B7097,
      0 4px 6px -2px #3B7097;
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    /* padding: 0.125rem 0.125rem; */
    list-style: none;
    background-color: #fff;
    border-radius: 0.125rem;
    display: none;
  }
  
  .dropdown.show {
    display: block;
    
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
    
  }
  

